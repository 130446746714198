import { Injectable } from '@angular/core';
import { LinkField } from '@prismicio/client';
import { ProductCatalogService, RequestParams } from '@yol-digital/ms-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Promotion } from 'interfaces';
import { PCProduct } from './interfaces/pc-product.class';
import { ProductService } from './product.service';
import CatalogResponse = ProductCatalogService.CatalogResponse;

@Injectable({
  providedIn: 'root',
})
export class EshopProductService extends ProductService {
  protected getCatalog(query?: { code?: string }, params?: RequestParams) {
    return this.api.public.getCatalog(query, params);
  }

  public getPromotionByCode(code: string) {
    return this.api.public.getPromotionByCode(code);
  }

  public getPCProducts$(): Observable<CatalogResponse[]> {
    return this.observableDataLoader.get('ProductCatalog', () =>
      this.getCatalog().pipe(map((res: { plans: CatalogResponse[] }) => res.plans))
    );
  }

  public getPCProductByCode(code: string) {
    return this.observableDataLoader.get('PC_product_' + code, () =>
      this.getCatalog({ code }).pipe(
        map((res: { plans: ProductCatalogService.CatalogResponse[] }) =>
          res.plans.find(product => product.code == code)
        )
      )
    );
  }

  public buildCheckoutPath(
    product: PCProduct,
    promotion?: Promotion,
    lineCheckId?: string
  ): { link: LinkField; queryParams?: object } {
    const queryParamsObj = this.buildCheckoutQueryParams(product, promotion, lineCheckId);
    const queryParamsArray = Object.entries(queryParamsObj);
    const queryParams = queryParamsArray.join('&').replace(/,/g, '=');

    return {
      link: {
        url: `${this.config.checkoutUrl}/${this.languageService.current}/start/${product.product_code}${
          queryParamsArray.length > 0 ? `?${queryParams}` : ''
        }`,
        link_type: 'Web',
      },
    };
  }
}
