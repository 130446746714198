import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  inject,
  input,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SvgComponent } from 'icon';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuService } from './menu.service';

let index = 0;

@Component({
  selector: 'lib-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, NgClass, RouterLink, RouterLinkActive, NgTemplateOutlet, MatMenuModule, SvgComponent],
})
export class MenuComponent {
  private menuService = inject(MenuService);
  icon = input<string>();
  iconClass = input<string>('');
  label = input('');
  a11yAltText = input('');
  outlined = input(false);
  collapseOnMobile = input(false);
  hideLabelOnMobile = input(false);
  lightTheme = input(false);
  @ContentChildren(MenuItemComponent) menuItems: QueryList<MenuItemComponent>;
  @ViewChild('menuContent', { read: TemplateRef, static: false }) private menuContent: TemplateRef<unknown>;
  @ViewChild('menuButton', { read: ElementRef }) private menuButton: ElementRef<HTMLElement>;
  a11yAltTextId = `menu-label-${index++}`;

  open() {
    this.menuService.open({ template: this.menuContent, positioningElement: this.menuButton, id: this.a11yAltTextId });
  }

  close() {
    this.menuService.close();
  }
}
