import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityService, PosCustomerLookupService } from '@yol-digital/ms-client';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { LineCheckInstallationAddress } from 'interfaces';
import { LanguageService } from 'language';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LineCheckProductsService } from 'line-check-products';
import { ToastService } from 'toast';
import { ENVIRONMENT_URLS_CONFIG_TOKEN, StorageKeys, StorageService } from 'utils';
import posCustomerLookupServiceApi = PosCustomerLookupService.Api;
import LanguageEnum = IdentityService.LanguageEnum;
import MethodEnum = IdentityService.MethodEnum;

@Injectable({
  providedIn: 'root',
})
export class CustomerLookupService {
  private config = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private languageService = inject(LanguageService);
  private storageService = inject(StorageService);
  private router = inject(Router);
  private toastService = inject(ToastService);
  private location = inject(Location);
  private lineCheckProductsService = inject(LineCheckProductsService);
  private readonly posCustomerLookup: posCustomerLookupServiceApi;

  constructor() {
    this.posCustomerLookup = new posCustomerLookupServiceApi(this.config.newMicroServiceEndpoint, inject(HttpClient));
  }

  public searchByPersonalDetails(firstname: string, lastname: string, dob: string) {
    return this.posCustomerLookup.search.searchByPersonalDetails({ firstname, lastname, dob }).pipe(
      tap((res: PosCustomerLookupService.SearchByPersonalDetailsRes) => {
        this.setCustomerRefIdOnStorage(res.searchRefId);
      })
    );
  }

  public searchByEmailOrPhone(value: string) {
    if (value?.includes('@')) {
      const email = value;
      return this.posCustomerLookup.search.searchByEmailStart({
        email,
        language: this.languageService.current as LanguageEnum,
      });
    } else {
      const phoneNumber = value;
      return this.posCustomerLookup.search.searchByPhoneStart({
        phoneNumber,
        language: this.languageService.current as LanguageEnum,
      });
    }
  }

  public confirmEmailOrPhone(otpRefId: string, code: string | undefined, methodUsed: MethodEnum): Observable<unknown> {
    const payload: PosCustomerLookupService.SearchConfirmReq = {
      otpRefId,
      code,
    };

    if (methodUsed === 'email') {
      return this.posCustomerLookup.search.confirmEmail(payload).pipe(
        tap((res: PosCustomerLookupService.SearchByPersonalDetailsRes) => {
          this.setCustomerRefIdOnStorage(res.searchRefId);
        }),
        map(response => response)
      );
    } else {
      return this.posCustomerLookup.search.confirmPhone(payload).pipe(
        tap((res: PosCustomerLookupService.SearchByPersonalDetailsRes) => {
          this.setCustomerRefIdOnStorage(res.searchRefId);
        }),
        map(response => response)
      );
    }
  }

  public get customerRefIdFromStorage() {
    return this.storageService.get<string>(StorageKeys.CustomerRefId);
  }

  public accountDetailsBySearchRefId() {
    if (!this.customerRefIdFromStorage) {
      this.removeCurrentCustomerFromSession();
      return of(null);
    }
    return this.posCustomerLookup.customer.getCustomerAccount(this.customerRefIdFromStorage).pipe(
      map(account => {
        if ('error' in account) throw new Error(account.error);
        return account;
      }),
      tap(account => {
        this.setCustomerUsernameOnStorage(account.ownerDetails?.username);
        if (account.billingAddress) {
          this.setCustomerLineCheckAddressToStorage({
            postCode: account.billingAddress.zip,
            city: account.billingAddress.city,
            streetName: account.billingAddress.street,
            streetNumber: account.billingAddress.streetNumber,
            ...(account.billingAddress.co ? { co: account.billingAddress.co } : {}),
          });
        }
      }),
      catchError(err => {
        if (!err?.wasCaught) {
          this.removeCurrentCustomerFromSession();
          this.toastService.add('Customer Reference Id expired. Going back to Customer Find page...', false, 4000);
          setTimeout(() => {
            this.router.navigate([this.languageService.current, 'customer-find']);
          }, 4000);
        }
        return of(false);
      })
    );
  }

  public setCustomerRefIdOnStorage(customerReferenceId: string) {
    this.storageService.set(StorageKeys.CustomerRefId, customerReferenceId, true);
  }

  public setCustomerUsernameOnStorage(customerUsername: string) {
    this.storageService.set(StorageKeys.CustomerUsername, customerUsername, true);
  }

  public setCustomerLineCheckAddressToStorage(address: LineCheckInstallationAddress) {
    this.storageService.set<LineCheckInstallationAddress>(StorageKeys.LineCheckAddressPos, address);
  }

  public get customerUsername() {
    return this.storageService.get<string>(StorageKeys.CustomerUsername);
  }

  public removeCurrentCustomerFromSession() {
    this.storageService.remove(StorageKeys.CustomerRefId);
    this.storageService.remove(StorageKeys.CustomerUsername);
    this.storageService.remove(StorageKeys.LineCheckAddressPos);
    this.lineCheckProductsService.resetLineCheckForm();
  }

  public hasCustomerSessionActive() {
    return !!this.storageService.get(StorageKeys.CustomerRefId);
  }

  public handleCustomerNotInSession() {
    return this.hasCustomerSessionActive() ? true : this.location.back();
  }

  public updateCustomerContactPhone(data: PosCustomerLookupService.UpdateContactNumberReq) {
    return this.posCustomerLookup.customer.updateContactNumber(this.customerRefIdFromStorage, data);
  }

  public updateCustomerEmail(email?: string) {
    return this.posCustomerLookup.customer.updateContactEmail(this.customerRefIdFromStorage, { newEmail: email });
  }

  public updateCustomerBillingAddress(data: PosCustomerLookupService.UpdateBillingAddressReq) {
    return this.posCustomerLookup.customer.updateContactBillingAddress(this.customerRefIdFromStorage, data);
  }
}
